import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Heading, Text } from "@breakingwave/react-ui-components";
import Layout from "../../../../components/layout";
import Container from "../../../../components/grid/Container";
import Row from "../../../../components/grid/Row";
import Column from "../../../../components/grid/Column";
import dots from "../../../../assets/images/mixed-space-dots-circular@3x.png";
import { useOnboardingProjectAdminContext } from "../_state/context";
import { footerProps, headerProps, htmlHeadProps } from "./OnboardingProjectAdminThanks.config";
import * as S from "./OnboardingProjectAdminThanks.styles";

const OnboardingProjectAdminThanks: React.FC = () => {
    
    const navigate = useNavigate();    
    const [searchParams] = useSearchParams();

    const { state } = useOnboardingProjectAdminContext();

    const { 
        ManagedProcess: projectAdminAssignment 
    } = state;

    useEffect(() => {        
        const { id, t } = Object.fromEntries([...searchParams]); 

        // If the user hit F5 then the managed process will not be
        // in state and so we need to go back to the landing page.
        if (!projectAdminAssignment) {
            if (id && t) {
                console.log("Navigating from 'Project Admin Thanks' to 'Project Admin Onboarding Landing'.  User probably hit F5");
                navigate(`/project-admin-onboarding?id=${id}&t=${t}`);
            } else {
                console.log("Parameters error. Redirecting to root.");
                navigate("/");
            }
        }
    }, []);   

    return (
        <Layout htmlHeadProps={htmlHeadProps} headerProps={headerProps} footerProps={footerProps}>
            <S.PageTitleSection>
                <Container isResponsive={true}>
                    <Row>
                        <Column>
                            <S.Hr color="#595959" style={{ marginTop: "8px" }} />
                        </Column>
                    </Row>
                </Container>
            </S.PageTitleSection>
            <S.BodySection>
                <Container isResponsive={true}>
                    <Row>
                        <Column md={{ size: 4, offset: 4 }}>
                            <img
                                src={dots}
                                alt="thank you page mixed space dots circular"
                                style={{ marginBottom: 16 }}
                            />
                        </Column>
                    </Row>

                    <Row>
                        <Column md={{ size: 6, offset: 3 }}>
                            <Heading variant="h5" style={{ marginBottom: 16 }}>
                                    Thank you.
                                <br />
                            </Heading>
                        </Column>
                        <Column md={{ size: 4, offset: 4 }}>
                            <Text color="white.75">
                                We now have all of the information we need in order to get you started. 
                                You will receive a welcome email with your credentials shortly. 
                                Please keep an eye on your inbox.
                            </Text>
                        </Column>
                    </Row>
                  

                </Container>
            </S.BodySection>
        </Layout>
    );
};

export default OnboardingProjectAdminThanks;
