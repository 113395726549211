import axios from "axios";
import { useMutation, UseMutationResult } from "react-query";
import { protectedResources } from "../auth/authConfig";
import { ICreateGenericRequest, IGenericRequest } from "../types/genericRequests";
import useAadAccessToken from "../hooks/useAadAccessToken";

const createGenericRequest = async (
    accessToken: string,
    payload: ICreateGenericRequest
): Promise<IGenericRequest> => {
    const url = `${protectedResources.wavecore.endpoint}/api/GenericRequests`;
    const response = await axios({
        method: "post",
        url,
        data: payload,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`        
        }
    });

    return response.data;
};

const useCreateGenericRequest = (): UseMutationResult<IGenericRequest, unknown, ICreateGenericRequest, unknown> => {
    const { getAccessToken, isAuthenticated } = useAadAccessToken(protectedResources.wavecore.scopes);
    return useMutation((vars) => 
        getAccessToken().then((accessToken) => {
            if (accessToken && isAuthenticated) {
                return createGenericRequest(accessToken, vars);
            }
            return Promise.reject();      
        }));
};

export default useCreateGenericRequest;
