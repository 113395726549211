import { IOnboardingState } from "./types";

export const initialOnboardingState: IOnboardingState = {
    ManagedContext: {
        Id: undefined,
        ResourceType: undefined
    },
    ManagedProcess: undefined,
    AccessToken: "",
    ProcessingTask: undefined,
    DeviceRequest: {
        ContactNumber: "",
        DeviceType: undefined,
        VmConfirm: "",
        PostalAddress: {
            StreetAddress: "",
            State: "",
            City: "",
            Country: "",
            PostalCode: ""
        }
    }
};
