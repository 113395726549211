import React from "react";
import { Heading, Text } from "@breakingwave/react-ui-components";
import Container from "../../../../components/grid/Container";
import Row from "../../../../components/grid/Row";
import Column from "../../../../components/grid/Column";
import * as S from "./Attestation.styles";

const GuestsV1: React.FC = () => (<>
    <S.PageTitleSection>
        <Container isResponsive={true}>
            <Row>
                <Column>
                    <Heading variant="h5" weight={300}>
                        Community Guest Agreement
                    </Heading>
                    <S.Hr color="#595959" style={{ margin: "16px 0 24px" }} />
                </Column>
            </Row>
            <Row>
                <Column>
                    <Text size={20} weight={300}>
                        Collaboration
                    </Text>
                    <Text size={20} weight={500} color="white.50">
                        Breaking Wave
                    </Text>
                </Column>
            </Row>
        </Container>
    </S.PageTitleSection>
    <S.OverviewSection>
        <Container isResponsive={true}>
            <Row>
                <Column md={7}>
                    <Heading variant="h5" color="white.75" style={{ marginBottom: 16 }}>
                        Overview
                    </Heading>
                    <Text color="white.75">
                        Breaking Wave DB Ltd. (Breaking Wave) is part of the Deutsche Bank group. Breaking Wave 
                        is air-gapped both physically through separate office space and hardware, and virtually 
                        through a separate software and development environment. The air-gap facilitates 
                        compliant collaboration with Deutsche Bank.
                        <br />
                        <br />
                        As a guest of our Community, you are granted access to spaces of Breaking Wave that are 
                        designed for collaboration. The service grants you access to a set of collaboration tools 
                        and content which has been uploaded or created within the space. Unauthorised 
                        distribution of this content outside of the collaboration box is forbidden.
                        <br />
                        <br />
                        This document is to confirm the overlay of additional policies and governance necessary to 
                        protect Breaking Wave. We ask that you confirm your understanding of your engagement 
                        with Breaking Wave by attestation.
                        <br />
                        <br />
                        This document is a Compliance requirement, not an offer of employment with Breaking Wave.
                    </Text>
                    <S.DescriptionListWrapper>
                        <dl>
                            <dt>
                                <Text color="white.75">
                                    Collaboration
                                </Text>
                            </dt>
                            <dd>
                                <Text color="white.75">
                                    The details of your collaboration are specified in your agreement with your hosts.
                                </Text>
                            </dd>
                            <dt>
                                <Text color="white.75">
                                    Location
                                </Text>
                            </dt>
                            <dd>
                                <Text color="white.75">
                                    The Breaking Wave Community is accessed remotely.
                                </Text>
                            </dd>
                            <dt>
                                <Text color="white.75">Breaking Wave Contact</Text>
                            </dt>
                            <dd>
                                <Text color="white.75">
                                    Community Support
                                    <br />
                                    <a
                                        href="mailto:community@breakingwave.com"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <u>community@breakingwave.com</u>
                                    </a>
                                </Text>
                            </dd>
                            <dt>
                                <Text color="white.75">
                                    Duration of your Access period
                                </Text>
                            </dt>
                            <dd>
                                <Text color="white.75">
                                    Start date is as agreed with your hosts for the duration of 
                                    one month and can be renewed if necessary. 
                                    You should only use the assets and services provided to you 
                                    by Breaking Wave for the purpose of your Breaking Wave collaboration.
                                </Text>
                            </dd>
                            <dt>
                                <Text color="white.75">
                                    Information
                                </Text>
                            </dt>
                            <dd>
                                <Text color="white.75">
                                    In order to maintain the Breaking Wave “air-gap”, it is imperative 
                                    that Breaking Wave is not directly or indirectly exposed 
                                    to ‘confidential’ DB non-public information, outside of 
                                    information that falls under the banks ‘Need to Know’ principles.
                                    <br />
                                    <br />
                                    Information falling under the ‘Need to Know’ Principles relates 
                                    strictly to information required for your collaboration. However, 
                                    it is imperative that Breaking Wave should never come into contact 
                                    with client or trade information, or information that is 
                                    deemed ‘confidential’ at any time. If you have a separate engagement 
                                    with Deutsche Bank, please treat Breaking Wave and Deutsche Bank 
                                    as separate, non-associated engagements.
                                </Text>
                            </dd>
                            <dt>
                                <Text color="white.75">
                                    System
                                </Text>
                            </dt>
                            <dd>
                                <Text color="white.75">
                                    All Breaking Wave assets and services fall under Deutsche Bank guidelines 
                                    for appropriate use in respect to the type of content accessed, and types of activity undertaken. 
                                </Text>
                            </dd>
                        </dl>
                    </S.DescriptionListWrapper>
                </Column>
            </Row>
        </Container>
    </S.OverviewSection>
    <S.AttestationSection>
        <Container isResponsive={true}>
            <Row>
                <Column md={7}>
                    <Heading variant="h4" style={{ marginBottom: 16 }}>
                        Attestation
                    </Heading>
                    <Text color="white.75">
                        I hereby attest that I understand my obligations relating to obtaining guest access to the 
                        Breaking Wave Community, in accordance with the information outlined above.
                        <br />
                        <br />
                        Furthermore and specifically, I understand the importance of not disclosing nor using, 
                        in any manner, any Deutsche Bank non public information within the Breaking Wave 
                        environment, outside of information relating to my project. The use of DB confidential 
                        and/or unmasked client data is prohibited in Breaking Wave. Any separate engagement 
                        with Deutsche Bank, not relating to my project, will be considered as mutually 
                        exclusive.
                        <br />
                        <br />
                        Additionally, I confirm that I shall only use my Breaking Wave credentials to conduct 
                        activity related to my collaboration – including the use of provisioned tools.
                        <br />
                        <br />
                        Moreover, I agree to disclose if I am subject to an MTA (Mandatory Time Away) obligation. 
                        Should this be the case I will notify Breaking Wave and the Collaboration Box Administrator 
                        of my absence period and will not access the Breaking Wave environment during this time.
                        <br />
                        <br />
                        Through submitting this form, I confirm my understanding and acceptance of the 
                        engagement terms with Breaking Wave. I understand that this is an agreement to gain 
                        access to Breaking Wave services, and not an employment contract.
                    </Text>

                </Column>
            </Row>
        </Container>
    </S.AttestationSection>
</>);

export default GuestsV1;
