import axios from "axios";
import { useMutation, UseMutationResult } from "react-query";
import {
    ILeaveProjectRequest, ILeaveProjectResponse
} from "../types/leaveProject";
import useAadAccessToken from "../hooks/useAadAccessToken";
import { protectedResources } from "../auth/authConfig";

const createLeaveProject = async (
    accessToken: string,
    request: ILeaveProjectRequest,
    projectSlug: string
): Promise<ILeaveProjectResponse> => {
    const response = await axios({
        method: "POST",
        url: `${protectedResources.wavecore.endpoint}/api/LeaveProjects/${projectSlug}`,
        data: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${accessToken}`
        }
    });

    return response.data;
};

const useCreateLeaveProject = (
    projectSlug: string
): UseMutationResult<ILeaveProjectResponse, unknown, ILeaveProjectRequest, unknown> => {
    const { getAccessToken, isAuthenticated } = useAadAccessToken(
        protectedResources.wavecore.scopes
    );
    return useMutation((vars) =>
        getAccessToken().then((accessToken) => {
            if (accessToken && isAuthenticated) {
                return createLeaveProject(accessToken, vars, projectSlug);
            }
            return Promise.reject();
        })
    );
};

export default useCreateLeaveProject;
