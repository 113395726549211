import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Spinner from "../../../../components/spinner";
import { ManagedProcessResourceTypeEnum, TaskGroupStatusesEnum } from "../../../../types/shared";
import { OnboardingProjectAdminActionTypesEnum, useOnboardingProjectAdminContext } from "../_state/context";
import { IProjectAdminAssignmentResponse, ProjectAdminAssignmentTaskAutomationKeyEnum } from "../../../../types/projectAdminAssignment";
import useGetManagedProcessByAccessToken from "../../../../api/getManagedProcessByAccessToken";
import * as S from "./OnboardingProjectAdminLandingPage.styles";

const OnboardingProjectAdminLandingPage: React.FC = () => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [message, setMessage] = useState<string>("Validating link. Please wait...");    
    const [errorMessage, setErrorMessage] = useState<string>();

    const { 
        state: {
            ManagedContext,
            ManagedProcess: projectAdminAssignment,
            AccessToken: OnboardingAccessToken
        },
        dispatch 
    } = useOnboardingProjectAdminContext();

    const {
        data: managedProcessResult,
        isSuccess: approvalDataLoadSuccess
    } = useGetManagedProcessByAccessToken<IProjectAdminAssignmentResponse>(
        ManagedContext, 
        ProjectAdminAssignmentTaskAutomationKeyEnum.SEND_PROJECT_ADMIN_REQUEST_EMAIL, 
        OnboardingAccessToken, {
            enabled: !!(ManagedContext && OnboardingAccessToken) && !errorMessage,
            cacheTime: 0,
            refetchInterval: 5000
        });

    useEffect(() => {
        const timeOut = setTimeout(() => {
            setErrorMessage("This link may have expired.");
        }, 60000);

        return (() => {
            clearTimeout(timeOut);
        });
    }, []);

    useEffect(() => {
        const { id, t } = Object.fromEntries([...searchParams]); 

        if (id && t) {
            dispatch({
                type: OnboardingProjectAdminActionTypesEnum.SET_ONBOARDING_CONTEXT,
                payload: {
                    Id: id,
                    ResourceType: ManagedProcessResourceTypeEnum.ProjectAdminAssignment
                }
            });

            dispatch({
                type: OnboardingProjectAdminActionTypesEnum.SET_ONBOARDING_ACCESSTOKEN,
                payload: t
            });
        } else {
            setErrorMessage("Parameters invalid");
        }
    }, []);

    useEffect(() => {
        if (managedProcessResult && ManagedContext) {
            dispatch({
                type: OnboardingProjectAdminActionTypesEnum.SET_ONBOARDING_MANAGED_PROCESS,
                payload: managedProcessResult
            });
        }
    }, [managedProcessResult, ManagedContext]);

    useEffect(() => {
        if (managedProcessResult && projectAdminAssignment && approvalDataLoadSuccess) {
            setMessage("Checking state. Please wait...");

            const termsTask = managedProcessResult.TaskItems.find((t) => t.TaskId === ProjectAdminAssignmentTaskAutomationKeyEnum.PROJECT_ADMIN_TERMS_AGREED.toString());
            if (!termsTask) {
                setErrorMessage("Terms task is missing");
                return;
            }

            if (termsTask?.Status === TaskGroupStatusesEnum.Pending) {
                console.log("Navigating to 'project admin terms'");
                navigate(`/project-admin-terms-task?id=${ManagedContext.Id}&t=${OnboardingAccessToken}`);
                return;
            }

            if (termsTask.Status === TaskGroupStatusesEnum.Complete) {

                console.log("Navigating to 'project admin thank you'");
                navigate(`/project-admin-onboarding-thank-you?id=${ManagedContext.Id}&t=${OnboardingAccessToken}`);
                return;
            }        
        }
    }, [managedProcessResult, projectAdminAssignment, approvalDataLoadSuccess]);

    return (
        <S.StyledOnboardingLandingPage>
            {!errorMessage && 
                <S.LoadingSection>
                    <Spinner padding="20px" />
                    <h1>
                        {message}
                    </h1>
                </S.LoadingSection>
            }

            {errorMessage &&
                <S.ErrorWrapper>
                    <h1>
                       An error has occurred whilst determining your onboarding status
                    </h1>
                    <p>
                        {errorMessage}
                    </p>
                </S.ErrorWrapper>
            }

        </S.StyledOnboardingLandingPage>
    );
};

export default OnboardingProjectAdminLandingPage;
