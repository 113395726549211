import React, { useEffect, useMemo } from "react";
import ReactPlayer from "react-player/lazy";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Checkbox, Heading, Text } from "@breakingwave/react-ui-components";
import Layout from "../../../../components/layout";
import Container from "../../../../components/grid/Container";
import Row from "../../../../components/grid/Row";
import Column from "../../../../components/grid/Column";
import { useOnboardingContext } from "../_state/context";
import Spinner from "../../../../components/spinner";
import { DeviceTypeEnum, TaskItemStatusEnum } from "../../../../types/shared";
import { ProjectTeamAssignmentTaskAutomationKeyEnum } from "../../../../types/onboarding";
import useNotifyAutomationService from "../../../../api/notifyAutomationService";
import useGetManagedProcessTaskStatus from "../../../../api/getManagedProcessTaskStatus";
import { htmlHeadProps, headerProps, footerProps } from "./Training.config";
import * as S from "./Training.styles";

const Training: React.FC = () => {
    const [checked, setChecked] = React.useState(false);
    const [confirmTrainingCheckbox, setConfirmTrainingCheckbox] = React.useState<boolean>(false);
    const [customSeekingControl, setCustomSeekingControl] = React.useState<boolean>(false);
    const player = React.useRef<ReactPlayer>(null);

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const { state } = useOnboardingContext();

    const bypassVideo =
        window.location.href.includes("http://localhost") || window.location.href.includes("breakingwave.dev");

    const {
        ManagedContext,
        ManagedProcess: projectTeamAssignment,
        AccessToken: OnboardingAccessToken
    } = state;
    
    const projectSlug = useMemo(() => projectTeamAssignment?.ProjectSlug ?? "",[projectTeamAssignment]);   
    const trainingAccessToken = useMemo(() => projectTeamAssignment?.TaskItems.find((t) => t.TaskId === ProjectTeamAssignmentTaskAutomationKeyEnum.TRAINING_COMPLETE.toString())?.AccessToken, []);
    const deviceRequirementAccessToken = useMemo(() => projectTeamAssignment?.TaskItems.find((t) => t.TaskId === ProjectTeamAssignmentTaskAutomationKeyEnum.DEVICE_REQUIREMENT_COMPLETE.toString())?.AccessToken, []);    

    const {
        isLoading: isSubmittingTraining,
        mutateAsync: submitTraining,
        isSuccess: isSubmitTrainingSuccess
    } = useNotifyAutomationService(ManagedContext, projectSlug, trainingAccessToken, ProjectTeamAssignmentTaskAutomationKeyEnum.TRAINING_COMPLETE);

    const {
        data: getDeviceRequirementTastStatusResult,
        isFetching: isGetDeviceRequirementTaskStatusFetching
    } = useGetManagedProcessTaskStatus(
        ManagedContext,
        projectSlug,
        ProjectTeamAssignmentTaskAutomationKeyEnum.DEVICE_REQUIREMENT_COMPLETE,
        deviceRequirementAccessToken,
        {
            enabled: !!ManagedContext.Id && 
                     !!projectSlug &&
                     !!ManagedContext.ResourceType && 
                     isSubmitTrainingSuccess,
            refetchInterval: 2500
        }
    );

    useEffect(() => {        
        const { id, t } = Object.fromEntries([...searchParams]); 

        // If the user hit F5 then the managed process will not be
        // in state and so we need to go back to the landing page.        
        if (!projectTeamAssignment) {
            if (id && t) {
                console.log("Navigating from 'Training' to 'Onboarding Landing'.  User probably hit F5");
                navigate(`/onboarding?id=${id}&t=${t}`);
            } else {
                console.log("Parameters error. Redirecting to root.");
                navigate("/");
            }
        }
    }, []);    

    useEffect(() => {
        // Spin until the device requirement task is ready in Monday.com.
        // Then go to the device requirement page.        

        if (!isSubmitTrainingSuccess) {
            console.log("Can't go to device requirement. 'isSubmitTrainingSuccess' is false");
            return;
        }

        if (isGetDeviceRequirementTaskStatusFetching) {
            console.log("Can't go to device requirement. 'isGetDeviceRequirementTaskStatusFetching' is fetching");
            return;
        }

        if (!getDeviceRequirementTastStatusResult) {
            console.log("Can't go to device requirement. 'getDeviceRequirementTastStatusResult' is undefined");
            return;
        }
             
        if (getDeviceRequirementTastStatusResult?.TaskStatus === TaskItemStatusEnum.Complete) {
            console.log("Device requirement is already complete.. going back to onboarding landing");
            navigate(`/onboarding?id=${ManagedContext.Id}&t=${OnboardingAccessToken}`);
            return;
        }

        if (getDeviceRequirementTastStatusResult?.TaskStatus !== TaskItemStatusEnum.Pending) {
            console.log(`Can't go to device requirement.  Device Requirement Task Status: ${getDeviceRequirementTastStatusResult?.TaskStatus}`);
            return;            
        }

        console.log("Going to device requirement...");
        navigate(`/device-requirement?id=${ManagedContext.Id}&t=${OnboardingAccessToken}`);
        
    }, [isSubmitTrainingSuccess, getDeviceRequirementTastStatusResult, isGetDeviceRequirementTaskStatusFetching]);

    const confirmTraining = async (): Promise<void> => {
        await submitTraining(null);
    };

    useEffect(() => {
        let calculatedCurrentTime = 0;
        const video = player.current?.getInternalPlayer();

        if (!bypassVideo && video) {
            const timeupdate = (): void => {
                if (!video?.seeking) {
                    calculatedCurrentTime = video.currentTime;
                }
            };

            const seeking = (): void => {
                const delta = video.currentTime - calculatedCurrentTime;

                if (Math.abs(delta) > 0.01) {
                    video.currentTime = calculatedCurrentTime;
                }
            };

            const ended = (): void => {
                calculatedCurrentTime = 0;
                setChecked(true);
            };

            video.addEventListener("timeupdate", timeupdate);
            video.addEventListener("seeking", seeking);
            video.addEventListener("ended", ended);

            return (): void => {
                window.removeEventListener("timeupdate", timeupdate);
                window.removeEventListener("seeking", seeking);
                window.removeEventListener("ended", ended);
            };
        }
        return undefined;
    }, [customSeekingControl]);

    return (
        <>
            {(isGetDeviceRequirementTaskStatusFetching || isSubmitTrainingSuccess) && (
                <S.LoadingSection>
                    <Spinner padding="20px" />
                    {projectTeamAssignment?.DeviceType === DeviceTypeEnum.Laptop ?
                        <h1>Confirmation of your mandatory risk training has been submitted. Please wait while we get your next task ready.</h1> : 
                        <h1>Confirmation of your mandatory risk training has been submitted. Please wait.</h1>}
                </S.LoadingSection>
            )}
            {!isGetDeviceRequirementTaskStatusFetching && !isSubmitTrainingSuccess && (
                <Layout htmlHeadProps={htmlHeadProps} headerProps={headerProps} footerProps={footerProps}>
                    <S.PageTitleSection>
                        <Container isResponsive={true}>
                            <Row>
                                <Column>
                                    <Heading variant="h5" weight={300}>
                                        Mandatory risk training
                                    </Heading>
                                    <S.Hr color="#595959" style={{ margin: "16px 0 24px" }} />
                                </Column>
                            </Row>
                            <Row>
                                <Column>
                                    <Text size={20} weight={300}>
                                        Project
                                    </Text>
                                    <Text size={20} weight={500} color="white.50">
                                        {projectTeamAssignment?.ProjectName ?? searchParams.get("ProjectSlug")}
                                    </Text>
                                </Column>
                            </Row>
                        </Container>
                    </S.PageTitleSection>
                    <S.BodySection>
                        <Container isResponsive={true}>
                            <Row>
                                <Column md={7}>
                                    <Heading variant="h5" weight={300} color="white.75" style={{ marginBottom: 16 }}>
                                        Compliance training
                                    </Heading>
                                    <S.InformationText color="white.75">
                                        To complete your compliance training you will need to watch the video below.
                                        Once complete it is important that you confirm you have watched it by pressing
                                        the button.
                                        <br />
                                        Not doing so will delay your onboarding as you will not be permitted to the next
                                        step.
                                    </S.InformationText>
                                </Column>
                            </Row>
                            <Row>
                                <Column>
                                    <ReactPlayer
                                        url={`${process.env.REACT_APP_VIDEO_ASSETS_CDN}/BW_Training_vExternal.mp4`}
                                        ref={player}
                                        config={{
                                            file: {
                                                attributes: {
                                                    controlsList: "noplaybackrate nodownload"
                                                }
                                            }
                                        }}
                                        onContextMenu={(e: Event) => e.preventDefault()}
                                        playing={false}
                                        controls={true}
                                        pip={false}
                                        width="100%"
                                        height="auto"
                                        onPlay={() => setCustomSeekingControl(true)}
                                        onEnded={(): void => setChecked(!checked)}
                                        style={{ marginBottom: 48 }}
                                    />
                                </Column>
                            </Row>
                            <Row>
                                <Column>
                                    <Checkbox
                                        name="confirmTrainingCheck"
                                        onClick={() => setConfirmTrainingCheckbox(!confirmTrainingCheckbox)}
                                    >
                                        I confirm that I have completed my compliance training.
                                    </Checkbox>
                                    <Button
                                        onClick={confirmTraining}
                                        disabled={
                                            !confirmTrainingCheckbox || !checked || isSubmittingTraining
                                        }

                                        style={{ marginTop: 24 }}
                                    >
                                        Confirm
                                    </Button>
                                </Column>
                            </Row>
                        </Container>
                    </S.BodySection>
                </Layout>
            )}
        </>
    );
};

export default Training;
