import React, { useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useOnboardingContext } from "../_state/context";
import Spinner from "../../../../components/spinner";
import { TaskItemStatusEnum } from "../../../../types/shared";
import getOnboardingTaskStatusLoadingDescription from "../../../../utils/getOnboardingTaskStatusLoadingDescription";
import useGetManagedProcessTaskStatus from "../../../../api/getManagedProcessTaskStatus";
import * as S from "./ProcessTask.styles";

const ProcessTask: React.FC = () => {

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const { state } = useOnboardingContext();

    const {
        ManagedContext,
        ManagedProcess: projectTeamAssignment,
        AccessToken: OnboardingAccessToken,
        ProcessingTask: automationKey
    } = state;

    const projectSlug = useMemo(() => projectTeamAssignment?.ProjectSlug ?? "",[projectTeamAssignment]);
    const taskAccessToken = useMemo(() => projectTeamAssignment?.TaskItems.find((t) => t.TaskId === automationKey?.toString())?.AccessToken, [automationKey]);

    const {
        data: getTastStatusResult,
        isFetching: isGetTaskStatusFetching
    } = useGetManagedProcessTaskStatus(
        ManagedContext,
        projectSlug,
        automationKey!,
        taskAccessToken,
        {
            enabled: !!ManagedContext.Id && 
                     !!projectSlug &&
                     !!ManagedContext.ResourceType &&
                     !!automationKey,
            refetchInterval: 2500
        }
    );

    useEffect(() => {        
        const { id, t } = Object.fromEntries([...searchParams]);

        // If the user hit F5 then the managed process will not be
        // in state and so we need to go back to the landing page.        
        if (!projectTeamAssignment) {
            if (id && t) {
                console.log("Navigating from 'Create User' to 'Onboarding Landing'.  User probably hit F5");
                navigate(`/onboarding?id=${id}&t=${t}`);
            } else {
                console.log("Parameters error. Redirecting to root.");
                navigate("/");
            }
        }
    }, []);

    useEffect(() => {
        // Spin until the task is ready in Monday.com.
        // Then go to back to the onboarding landing page to process the next task page.

        if (getTastStatusResult?.TaskStatus === undefined ||
            getTastStatusResult?.TaskStatus === TaskItemStatusEnum.Pending || 
            getTastStatusResult?.TaskStatus === TaskItemStatusEnum.InProgress) {
            console.log(`Task is still processing. Status for automation key '${automationKey}' is ${getTastStatusResult?.TaskStatus}`);
            return;
        }

        console.log("Going back to onboarding landing page to process the next task...");
        navigate(`/onboarding?id=${ManagedContext.Id}&t=${OnboardingAccessToken}`);
        
    }, [isGetTaskStatusFetching, getTastStatusResult]);

    return (
        <S.ProcessTaskPage>
            <S.LoadingSection>
                <Spinner padding="20px" />
                <h1>
                    {getOnboardingTaskStatusLoadingDescription(automationKey)}
                </h1>
            </S.LoadingSection>
        </S.ProcessTaskPage>
    );
};

export default ProcessTask;
