import React, { useMemo } from "react";
import { Heading, Text } from "@breakingwave/react-ui-components";
import Container from "../../../../components/grid/Container";
import Column from "../../../../components/grid/Column";
import Row from "../../../../components/grid/Row";
import { ReactComponent as Helpdesk } from "../../../../assets/icons/help_desk_icon.svg";
import { ReactComponent as AddingAVendor } from "../../../../assets/icons/adding_vendor_icon.svg";
import { ReactComponent as Feedback } from "../../../../assets/icons/feedback_icon.svg";
import { GenericRequestPageProvider } from "../../generic-requests/_state/context";
import GenericRequests from "../../generic-requests";
import * as S from "./index.styles";

const SupportTab: React.FC = () => {

    const enableGenericRequests = useMemo(() => process.env.REACT_APP_ENABLE_GENERIC_REQUESTS === "true", []);    

    return (
        <>
            <S.SupportTabBody>            
                <Container isResponsive={false}>
                    {enableGenericRequests &&
                        <GenericRequestPageProvider>
                            <GenericRequests />
                        </GenericRequestPageProvider>
                    }
                    <Row>
                        <Column>
                            <Heading variant="h5" weight={300} style={{ marginBottom: 12 }}>
                            Support
                            </Heading>
                            <Text weight={300} color="white.75">
                            Breaking Wave support is here to help you with the service we provide.
                                <br />
                            Find answers to common question, raise support tickets and more.
                            </Text>
                        </Column>
                    </Row>
                    <Row style={{ marginTop: 32 }}>
                        <Column xl={4}>
                            <S.SupportCard>
                                <S.SupportCardTitle>
                                    <Helpdesk />
                                    <Text size={16} weight={600}>
                                    Help Desk
                                    </Text>
                                </S.SupportCardTitle>
                                <Text size={14}>
                                If you are having technical issues please raise a ticket with our Helpdesk. The team
                                will be more than happy to help
                                </Text>
                                <S.SupportCardAction>
                                    <Text size={14}>
                                    Please email:
                                        <br />
                                    helpdesk@breakingwave.com
                                    </Text>
                                </S.SupportCardAction>
                            </S.SupportCard>
                        </Column>
                        <Column xl={4}>
                            <S.SupportCard>
                                <S.SupportCardTitle>
                                    <AddingAVendor />
                                    <Text size={16} weight={600}>
                                    Adding a Vendor
                                    </Text>
                                </S.SupportCardTitle>
                                <Text size={14}>
                                If you would like to add a new vendor to your project please get in touch
                                </Text>
                                <S.SupportCardAction>
                                    <Text size={14}>
                                    Please email:
                                        <br />
                                    community@breakingwave.com
                                    </Text>
                                </S.SupportCardAction>
                            </S.SupportCard>
                        </Column>
                        <Column xl={4}>
                            <S.SupportCard>
                                <S.SupportCardTitle>
                                    <Feedback />
                                    <Text size={16} weight={600}>
                                    Feedback
                                    </Text>
                                </S.SupportCardTitle>
                                <Text size={14}>
                                We&apos;re always keen to hear how we can improve our service. If you have an thoughts
                                or ideas please let us know
                                </Text>
                                <S.SupportCardAction>
                                    <Text size={14}>
                                    Please email:
                                        <br />
                                    community@breakingwave.com
                                    </Text>
                                </S.SupportCardAction>
                            </S.SupportCard>
                        </Column>
                    </Row>
                </Container>
            </S.SupportTabBody>
        </>);
};

export default SupportTab;
